import { useEffect, useState } from "react";
import { Input } from "components/common/form";
import CustomAmountField from "components/custom/CustomAmountField";
import useToolkit from "hooks/useToolkit";
import { RootState } from "store/store";
import { Info } from "lucide-react";
import CustomSwitch from "components/common/form/CustomSwitch";
import CustomNotePad from "components/custom/CustomNotePad";
import ShigaButton from "components/common/form/ShigaButton";
import usePaymentLinksProvider from "../context/usePaymentLinksProvider";
import { createPaymentLink } from "store/transactions/action";
import { defaultFixedLinkForm } from "../context/exports";
import useMediaService from "hooks/useMediaService";


type Props = {
   wallets: any;
}

const FixedAmountLink = ({ wallets }: Props) => {
   const { logoFile, endPaymentLinkSession } = usePaymentLinksProvider();
   const [form, setForm] = useState(defaultFixedLinkForm);

   const [note, setNote] = useState('');
   const { dispatch, useSelector } = useToolkit();
   const {
      allowedCurrenciesLoading,
      allowedCurrenciesData,
      createPaymentLinkLoading,
      createPaymentLinkData
   } = useSelector((state: RootState) => state.transactions);

   const { uploadFile, uploadedFileLoading } = useMediaService();
   const minimumInvalid = (form.amount?.length > 0 && Number(form.amount) < 1);
   const minAmountMsg = `The minimum amount you can send is 1 ${form.currency}`;

   const isLoading = uploadedFileLoading || allowedCurrenciesLoading || createPaymentLinkLoading;
   const formIsInvalid = isLoading || minimumInvalid || Number(form.amount) < 1 || form?.name?.length < 3

   const currenciesList = wallets?.map((item: any) => {
      const match = allowedCurrenciesData?.data.find((secondItem: any) => secondItem.currency === item.currency);

      return match ? { ...item, ...match } : item;
   })?.filter((item: any) => item?.canCollect === true && item?.currency !== 'AUD')?.map((item: any) => ({
      label: item.currency,
      value: item.currency,
      flagSrc: `/flags/${item.currency.toLowerCase()}.svg`
   }))

   const onSubmit = async (e: any) => {
      e.preventDefault();

      const { name, amount, currency, acceptOtherCurrencies, slug } = form

      let payload = {
         name,
         amount: Number(amount) * 100,
         currency: { value: currency, type: "FIXED" },
         description: note,
         slug,
         acceptOtherCurrencies,
         amountType: "FIXED",
         logoUrl: ""
      }

      if (logoFile && typeof logoFile !== "string") {
         const response: any = await uploadFile(logoFile);

         if (response?.success === true) {
            const logoUrl = response?.data?.file?.public;
            payload = { ...payload, logoUrl }
         }
      }
      dispatch(createPaymentLink(payload))
   }

   useEffect(() => {
      if (createPaymentLinkData !== null) {
         setForm(defaultFixedLinkForm);
         endPaymentLinkSession();
      }
      // eslint-disable-next-line
   }, [createPaymentLinkData])


   return (
      <div className="w-full">

         <form onSubmit={onSubmit} className="space-y-10">

            <Input
               required
               type="text"
               value={form.name}
               label="Name of Payment Link"
               onChange={(e: any) => setForm({ ...form, name: e.target.value })}
            />

            <CustomAmountField
               isFieldRequired
               showCurrenciesDropdown
               leftLabel='You receive'
               errorText={minAmountMsg}
               inputAmount={form.amount}
               inputError={minimumInvalid}
               dropdownCurrencies={currenciesList}
               transactionCurrency={form.currency}
               flagIconUrl={`/flags/${form.currency.toLowerCase()}.svg` || ""}
               handleSelection={(currency: string) => setForm({ ...form, currency })}
               setInputAmount={(e: any) => setForm({ ...form, amount: e.target.value })}
            />

            <div className="flex items-center w-full p-3 justify-between border border-shiga-gray-50 rounded-xl">
               <div className="flex items-center gap-2">

                  <Info
                     size={16}
                     className="text-white fill-neutral-400"
                  />

                  <div className="text-sm">
                     Accept payment in other currencies
                  </div>
               </div>

               <CustomSwitch
                  hideText
                  active={form.acceptOtherCurrencies}
                  onChange={() => setForm({ ...form, acceptOtherCurrencies: !form.acceptOtherCurrencies })}
               />
            </div>

            <div>
               <CustomNotePad
                  noteValue={note}
                  setNoteValue={setNote}
                  noteIsRequired={false}
                  notePadLeftLabel='Add a Description (optional)'
                  notePadPlaceholder='Say a bit about this link'
               />
            </div>

            <div className="w-full relative">
               <div className="pb-2 border-b border-shiga-gray-50">
                  <p className="mb-0 text-shiga-gray-200 font-medium text-xs">
                     Advanced Customer Options
                  </p>
               </div>

               <Input
                  type="text"
                  value={form.slug}
                  label="Custom URL"
                  placeholder="custom slug name"
                  onChange={(e: any) => setForm({ ...form, slug: e.target.value })}
               />

               <div className="absolute -bottom-6 text-shiga-gray-100">
                  https://checkout.payshiga.com/
                  <span className="text-shiga-dark-100">
                     {form.slug}
                  </span>
               </div>
            </div>

            <div className="py-7">
               <ShigaButton
                  darkBg
                  fullWidth
                  type='submit'
                  text='Create Link'
                  disabled={formIsInvalid}
                  loading={createPaymentLinkLoading}
               />
            </div>

         </form>

      </div>
   )
}

export default FixedAmountLink