import { SetStateAction, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import ResolveChargeback from "./ResolveChargeback";
import moment from "moment";
import Maybe from "components/common/Maybe";
import { RootState } from "store/store";
import useToolkit from "hooks/useToolkit";
import { resetChargebackParams } from "store/chargebacks/action";

type Props = {
  selected: any;
  sessionModalOpen: boolean;
  fetchChargebacks: () => void;
  setSessionModalOpen: React.Dispatch<SetStateAction<boolean>>;
};

const ChargebackDetailModal = ({
  selected,
  sessionModalOpen,
  setSessionModalOpen,
  fetchChargebacks
}: Props) => {
  const { dispatch, useSelector } = useToolkit();
  const { updateChargebackData } = useSelector((state: RootState) => state.chargebacks);

  const closeModal = () => {
    setSessionModalOpen(false);
  };

  const isPending = selected?.status === 'AWAITING_FEEDBACK';
  const isSuccess = selected?.status === 'ACCEPTED';
  const isFailed = selected?.status === 'DECLINED';

  const chargebackDetails = [
    {
      title: "Customer’s Name",
      value: selected?.customerName,
    },
    {
      title: "Amount",
      value: "USD " + selected?.amount,
    },
    {
      title: "Date created",
      value: moment(selected?.createdAt).format('MMM D, YYYY [at] h:mma'),
    },
    {
      title: "Due",
      value: selected?.due,
    },
    {
      title: "Status",
      value: selected?.status,
    },
  ];

  useEffect(() => {
    if (updateChargebackData !== null) {
      dispatch(resetChargebackParams());
      setSessionModalOpen(false);
      fetchChargebacks();
    }
    // eslint-disable-next-line 
  }, [updateChargebackData])

  return (
    <div>
      {sessionModalOpen === true && (
        <Dialog
          open={sessionModalOpen}
          className="fixed inset-0 z-50"
          onClose={() => setSessionModalOpen(false)}>
          <div className="fixed inset-0 bg-black/40" aria-hidden="true" />

          <div
            className={`mt-24 w-screen bg-white fixed inset-0 rounded-tr-3xl 
                        rounded-tl-3xl overflow-hidden shadow-xl animate__animated animate__faster          
                        ${sessionModalOpen
                ? "animate__slideInUp"
                : "animate__slideOutDown"
              }`}>
            <header className="sticky flex items-center justify-between px-6 lg:px-9 py-6 gap-3 border-b border-gray-100">
              <div className="pl-3 ml-3 text-xl text-shiga-dark-100 font-ojah font-bold">
                Resolve Chargeback
              </div>
              <button
                onClick={closeModal}
                className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
              >
                <IconClose className="m-auto text-white svg-stroke-gray" />
              </button>
            </header>
            <section className="relative  h-[calc(100%-88px)] ">
              <Dialog.Panel className="  h-full">
                <div className="md:flex lg:px-0 h-full">
                  <div className="w-2/6 border-r border-gray-100 pt-8 pb-12 pl-14 pr-2.5">
                    <div className="flex flex-col gap-y-6">
                      <h3 className="text-2xl font-bold">Details</h3>
                      {chargebackDetails.map((item, idx) => {
                        return (
                          <div key={idx * 2}>
                            <p className="text-subtext-gray text-sm ">
                              {item?.title}
                            </p>

                            <Maybe condition={item?.title !== 'Status'}>
                              <p className="text-lg font-medium">{item?.value}</p>
                            </Maybe>

                            <Maybe condition={item?.title === 'Status'}>

                              <div
                                className={`border rounded-md py-1 px-[5px] flexed w-max text-[12px] capitalize font-inter font-medium mt-1.5 
                                ${isSuccess ? 'border-shiga-green-50 text-shiga-green-100' :
                                    isPending ? 'border border-shiga-gray-75' :
                                      isFailed ? 'border-shiga-red-40 text-shiga-red-100' : ''}`}>
                                {isPending ? 'Awaiting feedback' : selected?.status?.toLowerCase()}
                              </div>
                            </Maybe>

                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="w-3/6 pt-8 pl-8 pr-2.5 ">
                    <div className="h-full">
                      <ResolveChargeback selected={selected} />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </section>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default ChargebackDetailModal;
