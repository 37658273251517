import { useEffect, useState } from "react";
import { ReactComponent as IconInfo } from "assets/images/icons/vital-info.svg";
import ProductImageHandler from "./../ProductImageHandler";
import ShigaButton from "components/common/form/ShigaButton";
import useEcomProvider from "../../context/useEcomProvider";
import useToolkit from "hooks/useToolkit";
import { RootState } from "store/store";
import Maybe from "components/common/Maybe";
import { Loader } from "lucide-react";

const EditProductImages = () => {
  const { useSelector } = useToolkit();
  const { activeProduct, productDetailsLoading } = useSelector(
    (state: RootState) => state.ecom
  );
  const [filesValid, setFilesValid] = useState(false);
  const {
    img1,
    setImg1,
    img2,
    setImg2,
    img3,
    setImg3,
    img4,
    setImg4,
    img5,
    img6,
    // setImg5,
    // setImg6,
    productImgs,
    setProductImgs,
    incrementSessionStep,
  } = useEcomProvider();

  const instructions = [
    "Uplaod only .png or .jpg images",
    "Upload 4 different high-quality photos of the same product",
  ];
  const imgCopies = [img1, img2, img3, img4, img5, img6]?.filter(
    (item) => item !== null
  );
  const btnDisabled = imgCopies?.length < 4 && filesValid === false;

  const handlers = [
    {
      onChange: (file: any) => setImg1(file),
      onDelete: () => setImg1(null),
      source: img1?.url || img1,
    },
    {
      onChange: (file: any) => setImg2(file),
      onDelete: () => setImg2(null),
      source: img2?.url || img2,
    },
    {
      onChange: (file: any) => setImg3(file),
      onDelete: () => setImg3(null),
      source: img3?.url || img3,
    },
    {
      onChange: (file: any) => setImg4(file),
      onDelete: () => setImg4(null),
      source: img4?.url || img4,
    },
    // { onChange: (file: any) => setImg5(file), onDelete: () => setImg5(null), source: img5?.url || img5 },
    // { onChange: (file: any) => setImg6(file), onDelete: () => setImg6(null), source: img6?.url || img6 },
  ];

  const onProceed = async () => {
    setProductImgs([]);

    if (img1 !== null) setProductImgs([...productImgs, img1]);
    if (img2 !== null) setProductImgs([...productImgs, img2]);
    if (img3 !== null) setProductImgs([...productImgs, img3]);
    if (img4 !== null) setProductImgs([...productImgs, img4]);
    // if (img5 !== null) setProductImgs([...productImgs, img5]);
    // if (img6 !== null) setProductImgs([...productImgs, img6]);

    setFilesValid(true);
    incrementSessionStep();
  };

  useEffect(() => {
    if (activeProduct !== null) {
      const { photos } = activeProduct;

      setImg1(photos[0]);
      setImg2(photos[1]);
      setImg3(photos[2]);
      setImg4(photos[3]);
      // if (photos[4] !== undefined) setImg5(photos[4]);
      // if (photos[5] !== undefined) setImg6(photos[5]);
    }
    // eslint-disable-next-line
  }, [activeProduct]);

  return (
    <div className="w-full">
      <Maybe condition={productDetailsLoading}>
        <div className="py-20 w-full flexed">
          <Loader className="animate-spin" size="5rem" />
        </div>
      </Maybe>

      <Maybe condition={!productDetailsLoading}>
        <div>
          <h6 className="text-shiga-dark font-medium mb-4">
            FOLLOW THE GUIDELINES BELOW
          </h6>
          {instructions.map((item, idx) => (
            <span key={idx} className="flex items-start mt-4">
              <div className="pt-1 mr-2.5">
                <IconInfo className="w-3 h-3" />
              </div>
              <p className="text-shiga-black text-sm">{item}</p>
            </span>
          ))}
        </div>

        <div className="py-14 flex items-center justify-between flex-wrap">
          {handlers.map(({ onChange, onDelete, source }: any, idx: number) => {
            return (
              <div key={idx} className={`w-[30%] ${idx <= 2 && "mb-8"}`}>
                <ProductImageHandler
                  disabled={false}
                  initialSrc={source}
                  onDelete={() => onDelete()}
                  onFileChange={(file) => onChange(file)}
                />
              </div>
            );
          })}
        </div>

        <ShigaButton
          darkBg
          fullWidth
          text="Continue"
          onClick={onProceed}
          disabled={btnDisabled}
        />
      </Maybe>
    </div>
  );
};

export default EditProductImages;
