import { Button } from "components/ui/button";
import useMediaService from "hooks/useMediaService";
import { dataURLtoFile, isBase64 } from "lib/convertToFile";
import { currencyFormat } from "lib/format.amount";
import { formatDate } from "lib/format.date";
import { createInvoice } from "lib/payments/invoices";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAuthPersist } from "store/auth/user/reducerPersist";
import { useAppSelector } from "store/hooks";
import { CurrencyType } from "types/misc";
import { useSharedStore } from "zustand-store";
import { useInvoiceStore } from "zustand-store/payments/invoice";
import { ReviewItem } from "./forms/components/ReviewItem";
import { defaultInvoice } from "types/payment/invoice";
import { Separator } from "components/ui/separator";

export const Review = () => {
  const { invoice, setInvoice, resetInvoice } = useInvoiceStore();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const { currencies } = useSharedStore();
  const { uploadFile } = useMediaService();
  const {
    userInfo: { businessProfile },
  } = useAppSelector(getAuthPersist);

  const total = useMemo(() => {
    return invoice.items.length > 0
      ? invoice.items.reduce((acc, item) => acc + item.price * item.qty, 0)
      : 0;
  }, [invoice.items]);

  const foundCurrency = useMemo(
    () =>
      currencies.find((item) => item.country === invoice.country)?.currency ||
      "NGN",
    [currencies, invoice.country]
  );
  const additionalCalc = useMemo(
    () => ({
      discount: total * (invoice.advancedCustomer.discount / 100),
      vat: total * (invoice.advancedCustomer.vat / 100),
    }),
    [invoice.advancedCustomer.discount, invoice.advancedCustomer.vat, total]
  );

  const handleCreateInvoice = async () => {
    const {
      advancedCustomer,
      description,
      endDate,
      items,
      reciepient,
      startDate,
      title,
    } = invoice;
    try {
      setSubmitting(true);
      let imageUrl = "";
      if (isBase64(invoice.imageUrl)) {
        const invoiceImage = dataURLtoFile(
          invoice.imageUrl,
          `${invoice.title}.png`
        );
        const uploadedResponse = await uploadFile(invoiceImage);
        imageUrl = uploadedResponse.data.file.link;
      }

      return createInvoice({
        currency: foundCurrency,
        description,
        discount: advancedCustomer.discount,
        endDate,
        startDate,
        note: description,
        items,
        recipientEmail: reciepient.recipientEmail,
        billingAddress: reciepient.address,
        recipientName: reciepient.recipientName,
        recipientPhone: reciepient.recipientPhone,
        title,
        vat: advancedCustomer.vat,
        imageUrl,
      })
        .then((data: any) => {
          toast.success(
            `Invoice for ${reciepient.recipientName} successfully created`
          );
          navigate("/payments/invoice");
          setInvoice(defaultInvoice);
          resetInvoice();
        })
        .catch((err) => {
          toast.error(
            "Something went wrong while creating invoice",
            err.message
          );
        })
        .finally(() => {
          setSubmitting(false);
        });
    } catch (err: any) {
      toast.error(`Something went wrong while uploading ${err.message}`);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center pt-6 space-y-6">
      <div className="w-[600px] h-[710px] rounded-xl border border-neutral-100 flex-col justify-start items-center gap-4 inline-flex">
        <div className="h-[230px] w-full  rounded-xl flex-col justify-start items-start flex">
          <div className="self-stretch px-4 pt-6 pb-5 bg-neutral-50 border-b border-neutral-100 justify-start items-center gap-3 inline-flex">
            {invoice.imageUrl.length ? (
              <img
                alt="invoice"
                className="w-10 h-10 rounded"
                src={invoice.imageUrl}
              />
            ) : (
              <></>
            )}
            {/* {
              invoice.invoiceNumber ? */}
            {invoice.invoiceNumber ? (
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-2.5 inline-flex">
                <div className="flex-col justify-start items-start gap-1 flex">
                  <div className="self-stretch text-neutral-400 text-xs font-normal font-['Inter'] uppercase">
                    INVOICE NO
                  </div>
                  <div className="self-stretch text-zinc-800 text-sm font-medium font-['Inter']">
                    {invoice.invoiceNumber ? invoice.invoiceNumber : ""}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* : <></>
            } */}
            <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
              <div className="flex-col justify-start items-start gap-1 flex">
                <div className="self-stretch text-right text-neutral-400 text-xs font-normal font-['Inter'] uppercase">
                  INVOICE DATE
                </div>
                <div className="self-stretch text-right text-zinc-800 text-sm font-medium font-['Inter']">
                  {`${formatDate(invoice.startDate)} - ${formatDate(
                    invoice.endDate
                  )}`}
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch px-4 py-9 rounded-bl-xl rounded-br-xl justify-start items-start gap-6 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-[13px] inline-flex">
              <div className="self-stretch h-[93px] flex-col justify-start items-start gap-2.5 flex">
                <div className="self-stretch text-neutral-400 text-xs font-normal font-['Inter']">
                  Business Address
                </div>
                <div className="self-stretch text-zinc-800 text-sm font-medium font-['Inter']">
                  {businessProfile?.address}
                </div>
              </div>
            </div>
            <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
              <div className="self-stretch h-[93px] flex-col justify-start items-start gap-2.5 flex">
                <div className="self-stretch text-right text-neutral-400 text-xs font-normal font-['Inter']">
                  Billing Address
                </div>
                <div className="self-stretch text-right text-zinc-800 text-sm font-medium font-['Inter']">
                  {invoice.reciepient.address.address}
                  <br />
                  {invoice.reciepient.address.postCode}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-[445px] flex-col w-full justify-start items-start flex">
          <div className="self-stretch px-4 py-3 bg-neutral-50 border-b border-neutral-100 justify-start items-start gap-3 inline-flex">
            <div className="w-60 flex-col justify-start items-start gap-2.5 inline-flex">
              <div className="text-neutral-400 text-xs font-medium font-['Inter'] uppercase">
                ITEMS
              </div>
            </div>
            <div className="w-28 flex-col justify-start items-start gap-2.5 inline-flex">
              <div className="text-neutral-400 text-xs font-medium font-['Inter'] uppercase">
                Cost
              </div>
            </div>
            <div className="w-20 flex-col justify-start items-start gap-2.5 inline-flex">
              <div className="text-neutral-400 text-xs font-medium font-['Inter'] uppercase">
                QUANTITY
              </div>
            </div>
            <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
              <div className="text-right text-neutral-400 text-xs font-medium font-['Inter'] uppercase">
                PRICE
              </div>
            </div>
          </div>
          <div className="self-stretch h-[406px] px-4 py-9 rounded-bl-xl rounded-br-xl flex-col justify-start items-start gap-6 flex">
            {invoice.items.map((item, idx) => (
              <ReviewItem
                key={idx}
                item={item}
                invoiceCurrency={foundCurrency as CurrencyType}
              />
            ))}

            <Separator />

            {/* <div className="self-stretch h-1 py-0.5 flex-col justify-start items-start gap-2.5 flex" /> */}
            <div className="self-stretch h-[136px] flex-col justify-start items-start gap-4 flex">
              <div className="self-stretch justify-start items-start gap-3 inline-flex">
                <div className="w-60 flex-col justify-start items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-white text-sm font-medium font-['Inter']">
                    Product Name
                  </div>
                </div>
                <div className="w-28 flex-col justify-start items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-zinc-800 text-sm font-normal font-['Inter']">
                    {`Subtotal (${invoice.items.length})`}
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
                  <div className="self-stretch text-right text-zinc-800 text-sm font-normal font-['Inter']">
                    {currencyFormat(total, foundCurrency)}
                  </div>
                </div>
              </div>
              <div className="self-stretch justify-start items-start gap-3 inline-flex">
                <div className="w-60 flex-col justify-start items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-white text-sm font-medium font-['Inter']">
                    Product Name
                  </div>
                </div>
                <div className="w-28 flex-col justify-start items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-zinc-800 text-sm font-normal font-['Inter']">
                    {`Discount (${invoice.advancedCustomer.discount}%)`}
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-center items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-white text-sm font-normal font-['Inter']">
                    2
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
                  <div className="self-stretch text-right text-zinc-800 text-sm font-normal font-['Inter']">
                    {currencyFormat(additionalCalc.discount, foundCurrency)}
                  </div>
                </div>
              </div>
              <div className="self-stretch justify-start items-start gap-3 inline-flex">
                <div className="w-60 flex-col justify-start items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-white text-sm font-medium font-['Inter']">
                    Product Name
                  </div>
                </div>
                <div className="w-28 flex-col justify-start items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-zinc-800 text-sm font-normal font-['Inter']">
                    {`VAT (${invoice.advancedCustomer.vat}%)`}
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-center items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-white text-sm font-normal font-['Inter']">
                    2
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
                  <div className="self-stretch text-right text-zinc-800 text-sm font-normal font-['Inter']">
                    {currencyFormat(additionalCalc.vat, foundCurrency)}
                  </div>
                </div>
              </div>
              <div className="self-stretch justify-start items-start gap-3 inline-flex">
                <div className="w-60 self-stretch" />
                {/* <div className="grow shrink basis-0 py-0.5 flex-col justify-start items-start gap-2.5 inline-flex" /> */}
              </div>
              <div className="flex justify-end items-end w-full">
                <Separator className=" w-[63%]" />
              </div>
              <div className="self-stretch justify-start items-start gap-3 inline-flex">
                <div className="w-60 flex-col justify-start items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-white text-sm font-medium font-['Inter']">
                    Product Name
                  </div>
                </div>

                <div className="w-28 flex-col justify-start items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-zinc-800 text-sm font-medium font-['Inter']">
                    Total
                  </div>
                </div>
                {/* <div className="grow shrink basis-0 flex-col justify-center items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-white text-sm font-normal font-['Inter']">
                    2
                  </div>
                </div> */}
                <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
                  <div className="self-stretch text-right text-zinc-800 text-sm font-medium font-['Inter']">
                    {currencyFormat(
                      total + additionalCalc.vat - additionalCalc.discount,
                      foundCurrency
                    )}
                  </div>
                </div>
              </div>
            </div>

            <Separator />

            <div className="self-stretch h-1 py-0.5 flex-col justify-start items-start gap-2.5 flex" />
            <div className="self-stretch justify-start items-start gap-3 inline-flex">
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-2.5 inline-flex">
                <div className="flex-col justify-start items-start gap-1 flex">
                  <div className="self-stretch text-neutral-400 text-xs font-normal font-['Inter'] uppercase">
                    Note
                  </div>
                  <div className="self-stretch text-zinc-800 text-sm font-normal font-['Inter']">
                    {invoice.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end items-end w-[600px]">
        <Button
          className=""
          isLoading={submitting}
          disabled={submitting}
          onClick={handleCreateInvoice}
        >
          Create & Send Invoice
        </Button>
      </div>
    </div>
  );
};
