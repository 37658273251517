import { RootState } from "store/store";
import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";
import { toast } from "react-toastify";

interface InitialState {
    chargebacksLoading: boolean;
    chargebacksData: any;

    updateChargebackLoading: boolean;
    updateChargebackData: any;
}

const initialState: InitialState = {
    chargebacksLoading: false,
    chargebacksData: null,

    updateChargebackLoading: false,
    updateChargebackData: null,
}


export default function reducer(state = initialState, { type, payload }: any): InitialState {
    switch (type) {

        case actions.RESET_CHARGEBACK_PARAMS:
            return {
                ...state,
                updateChargebackData: null,
            }

        case actions.UPDATE_CHARGEBACK_START:
            return {
                ...state,
                updateChargebackLoading: true,
                updateChargebackData: null,
            }

        case actions.UPDATE_CHARGEBACK_DONE:
            toast(payload.message)
            return {
                ...state,
                updateChargebackLoading: false,
                updateChargebackData: payload,
            }

        case actions.UPDATE_CHARGEBACK_FAILED:
            errorReducer(payload)
            return {
                ...state,
                updateChargebackLoading: false,
                updateChargebackData: payload,
            }

        case actions.GET_ALL_CHARGEBACKS_START:
            return {
                ...state,
                chargebacksLoading: true,
                chargebacksData: null,
            }

        case actions.GET_ALL_CHARGEBACKS_DONE:
            return {
                ...state,
                chargebacksLoading: false,
                chargebacksData: payload,
            }

        case actions.GET_ALL_CHARGEBACKS_FAILED:
            errorReducer(payload)
            return {
                ...state,
                chargebacksLoading: false,
                chargebacksData: payload,
            }

        default:
            return state;
    }
}


export const getRefundsStore = (state: RootState) => state.refunds;