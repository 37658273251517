import { SetStateAction } from "react";
import { Dialog } from '@headlessui/react';
import DynamicHeadTag from "components/common/DynamicHeadTag";
import LayoutDisplay from "./LayoutDisplay";
import ContextProvider from "../context/ContextProvider";
import useToolkit from "hooks/useToolkit";
import { closeProductEditModal } from "store/ecom/action";
import { RootState } from "store/store";

type Props = {
    sessionModalOpen: boolean;
    setSessionModalOpen: React.Dispatch<SetStateAction<boolean>>;
}

const InlineEcomLayout = ({ sessionModalOpen, setSessionModalOpen }: Props) => {
    const { dispatch, useSelector } = useToolkit();
    const { isEditingProduct } = useSelector((state: RootState) => state.ecom)


    return (
        <ContextProvider
            sessionModalOpen={sessionModalOpen}
            setSessionModalOpen={setSessionModalOpen}>

            <DynamicHeadTag
                headerText="Ojah"
            />

            {(sessionModalOpen || isEditingProduct) &&
                <Dialog
                    open={sessionModalOpen || isEditingProduct}
                    className="fixed inset-0 z-50"
                    onClose={() => {
                        dispatch(closeProductEditModal());
                        setSessionModalOpen(false);
                    }}>

                    <div className="fixed inset-0 bg-black/40" aria-hidden="true" />

                    <div
                        className={`mt-24 w-screen bg-white fixed inset-0 rounded-tr-3xl 
                        rounded-tl-3xl overflow-hidden shadow-xl animate__animated animate__faster          
                        ${(sessionModalOpen || isEditingProduct) ? "animate__slideInUp" : "animate__slideOutDown"}`}>

                        <LayoutDisplay setSessionModalOpen={setSessionModalOpen} />
                    </div>

                </Dialog>
            }
        </ContextProvider>
    );
}


export default InlineEcomLayout;
