import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthPageTitle from "components/common/AuthPageTitle";
import { ButtonLoader, Input, SelectInput } from "components/common/form";
import { ReactComponent as IconGlobe } from "assets/images/icons/Globe.svg";
import { ReactComponent as IconCheck } from "assets/images/icons/check-green.svg";
import useLoginInfo from "hooks/useLoginInfo";
import { toast } from "react-toastify";
import validate from "utils/validate";
import * as businessVerificationActions from "store/entities/businessVerification/action";
import * as userActions from "store/auth/user/action";
import { AddressField, googleAddressPayload } from "components/custom/GoogleAddressField";
import useToolkit from "hooks/useToolkit";
import Maybe from "components/common/Maybe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const BusinessInformation = (props: any) => {
    const { goNext } = props;
    const dispatch = useDispatch();
    const { userInfo } = useLoginInfo();
    const {
        businessVerificationUpdateLoading,
        businessVerificationUpdate,
        rcnAuthLoading,
        rcnAuthData
    } = useSelector((s: any) => s.entities.businessVerification);

    const [form, setForm] = useState({
        website: "",
        phoneNumber: "",
        email: "",
        tin: "",
        address: "",
        description: "",
        companyRegNumber: "",
        businessType: ""
    })
    const { businessProfile } = useToolkit();
    const [location, setLocation] = useState(businessProfile?.address || '');
    const businessIsNigerian = businessProfile?.country === 'NG';
    const businessRegNumberValid = form.companyRegNumber?.length === 7;

    // const validKybRcnRequests = businessProfile?.kybRequests?.filter((item: any) => item.fullCACData !== null);
    // const validRcnRecord = validKybRcnRequests?.find((item: any) => item?.fullCACData?.Name_of_Company?.length > 0);
    // const regNumberValidated = validRcnRecord !== undefined && validRcnRecord?.fullCACData?.Name_of_Company?.length > 0;
    // const rcnAuthSuccess = rcnAuthData?.message?.includes('CAC Validated Successfully') || regNumberValidated;

    const businessTypes = [
        { label: 'Business Name', value: 'BUSINESS_NAME' },
        { label: 'Company', value: 'COMPANY' },
        { label: 'Incorporated Trustees', value: 'INCORPORATED_TRUSTEES' }
    ]


    const populateForm = () => {
        setForm({
            website: businessProfile?.website ?? "",
            phoneNumber: businessProfile?.phoneNumber ?? "",
            email: businessProfile?.email ?? "",
            tin: businessProfile?.tin ?? "",
            address: location,
            description: businessProfile?.description ?? "",
            companyRegNumber: businessProfile?.companyRegNumber ?? "",
            businessType: ""
        });
    }

    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();

        const formCopy: any = { ...form };
        const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-]*)*$/;


        if (location?.length > 0 || (businessProfile && businessProfile?.address?.length > 0)) {
            delete formCopy.address
        }

        delete formCopy.website;
        delete formCopy.tin;
        delete formCopy.companyRegNumber;
        delete formCopy.businessType;

        if (form?.businessType?.length < 1) {
            toast.error('Please select a business type')
        } else if (form?.website?.length > 0 && urlPattern.test(form.website) === false) {
            toast.error('Please enter a valid website URL')
        } else if (businessIsNigerian && !businessRegNumberValid) {
            toast.error('Please provide a valid business registration number')
        } else {
            const errors: any = validate(formCopy);

            if (errors) {
                for (var x in errors) {
                    toast.error(errors[x]);
                };
                return;
            }
            else {
                dispatch(businessVerificationActions.updateBusinessInformation({
                    ...form,
                    address: businessProfile?.address || location || googleAddressPayload.description
                }));
            }
        }
    }

    useEffect(() => {
        if (businessProfile !== undefined) {
            populateForm();
        }
        // eslint-disable-next-line
    }, [userInfo]);

    useEffect(() => {
        dispatch(businessVerificationActions.resetRcnValidation())
        // eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     if (form?.businessType?.length > 0 && form?.companyRegNumber?.length === 7) {
    //         const actual = businessTypes?.find((item: any) => item.label === form.businessType)

    //         const payload = {
    //             rcNumber: form.companyRegNumber,
    //             type: actual?.value
    //         }

    //         dispatch(businessVerificationActions.validateRcn(payload))
    //     }
    //     // eslint-disable-next-line
    // }, [form?.businessType, form.companyRegNumber]);

    useEffect(() => {
        if (businessVerificationUpdate?.success === true) {
            dispatch(userActions.updateUserInfo({
                businessProfile: { ...businessVerificationUpdate?.data?.business ?? {} },
            }));
            dispatch(businessVerificationActions.resetUpdateBusinessVerification());
            goNext();
        }
        // eslint-disable-next-line
    }, [businessVerificationUpdate]);

    return (
        <div>
            <AuthPageTitle
                title={<>Add more details about<br />{userInfo?.businessProfile?.name}</>}
                description="This information would be used to validate your business."
            />
            <div className="pt-4 mt-10 border-t border-gray-100">
                <form onSubmit={submitForm}>

                    <Maybe condition={businessIsNigerian}>
                        <SelectInput
                            required
                            label="Business Type"
                            value={form.businessType}
                            onChange={(e: any) => {
                                setForm({ ...form, businessType: e.target.value })
                            }}>

                            <option value=''>
                                Select Business Type
                            </option>
                            {businessTypes?.map((item) => {
                                return (
                                    <option value={item?.label} key={item?.label}>
                                        {item.label}
                                    </option>
                                )
                            })}
                        </SelectInput>

                        <Input
                            maxLength={7}
                            type="number"
                            required={businessIsNigerian}
                            value={form.companyRegNumber}
                            placeholder="Registration number"
                            label="Business Registration number"
                            disabled={rcnAuthLoading}
                            onChange={(e: any) => setForm({ ...form, companyRegNumber: e.target.value })}
                            rightIcon={
                                rcnAuthLoading ?
                                    <FontAwesomeIcon
                                        spin
                                        icon="circle-notch"
                                        className=""
                                    />
                                    : null
                            }
                        />

                        <Maybe condition={rcnAuthData?.message?.includes('CAC Validated Successfully')}>
                            <div className="border border-dashed border-shiga-green-200 bg-shiga-green-25 text-shiga-green-300 font-medium text-sm lg:text-[16px] text px-4 py-2.5 w-full rounded-[10px] flex-between items-center mt-4">
                                <span>
                                    {rcnAuthData?.data?.businessName}
                                </span>

                                <IconCheck width={15} height={15} />
                            </div>
                        </Maybe>

                    </Maybe>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6">
                        <Input
                            type="tel"
                            label="Business Phone number"
                            placeholder="Phone number"
                            required={true}
                            value={form.phoneNumber}
                            onChange={(e: any) => setForm({ ...form, phoneNumber: e.target.value })}
                        />

                        <Input
                            type="email"
                            label="Support email"
                            placeholder="Email address"
                            required={true}
                            value={form.email}
                            onChange={(e: any) => setForm({ ...form, email: e.target.value })}
                        />
                    </div>

                    <AddressField
                        isRequired
                        addressValue={location}
                        inputLabel="Official Business Address"
                        setAddressValue={setLocation}
                    />

                    <Input
                        type="text"
                        label="Tax Identification Number (TIN)"
                        placeholder="Tax Identification Number"
                        required={false}
                        value={form.tin}
                        onChange={(e: any) => setForm({ ...form, tin: e.target.value })}
                    />

                    <Input
                        type="text"
                        label="Business website"
                        placeholder="www.abc.def"
                        required={false}
                        value={form.website}
                        onChange={(e: any) => setForm({ ...form, website: e.target.value })}
                        leftIcon={<IconGlobe />}
                        leftIconClass="px-2.5"
                    />

                    <div className="form-group">
                        <label>
                            Tell us about your business
                            <span className="form-input-required">*</span>
                        </label>
                        <div className="relative">
                            <textarea
                                className="w-full !h-32 form-input resize-none"
                                placeholder="What does your business do?"
                                value={form.description}
                                onChange={(e: any) => setForm({ ...form, description: e.target.value })}
                            >
                            </textarea>
                        </div>
                    </div>

                    <div className="flex space-x-2 items-center justify-end">
                        <ButtonLoader
                            type="submit"
                            loading={businessVerificationUpdateLoading || rcnAuthLoading}
                            // disabled={businessIsNigerian && !rcnAuthSuccess}
                            className="max-w-[16rem] btn btn-block btn-lg btn-primary">
                            Continue
                        </ButtonLoader>
                    </div>

                </form>
            </div>
        </div>
    );
}


export default BusinessInformation;