import ObjectToQueryString from "utils/ObjectToQueryString";
import * as actions from "./actionTypes";
import * as config from "data/config";

export function updateChargeback({ type, payload, id }: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `/business/${type}-chargeback/${id}`,
            method: "patch",
            data: { ...payload },
            onStart: actions.UPDATE_CHARGEBACK_START,
            onSuccess: actions.UPDATE_CHARGEBACK_DONE,
            onError: actions.UPDATE_CHARGEBACK_FAILED
        }
    }
}

export function getAllChargebacks(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAllChargebacks + ObjectToQueryString(payload),
            method: "get",
            data: {},
            onStart: actions.GET_ALL_CHARGEBACKS_START,
            onSuccess: actions.GET_ALL_CHARGEBACKS_DONE,
            onError: actions.GET_ALL_CHARGEBACKS_FAILED
        }
    }
}

export function resetChargebackParams() {
    return {
        type: actions.RESET_CHARGEBACK_PARAMS,
    }
}