import { useSelector } from "react-redux";
import { getAuthPersist } from "store/auth/user/reducerPersist";


const useFeatures = () => {
	const { userInfo } = useSelector(getAuthPersist);
	const businessProfile = userInfo?.businessProfile

	const allFeatures = businessProfile?.features;

	const terminalsFeatureAllowed = allFeatures?.find((item: any) => item?.feature === 'TERMINAL')?.status === true

	return {
		allFeatures,
		terminalsFeatureAllowed
	};

}


export default useFeatures;