import { useEffect } from "react";
import { ReactComponent as IconMore } from "assets/images/icons/More.svg";
import { ReactComponent as IconNavigation } from "assets/images/icons/arrow-left.svg";
import { ReactComponent as IconBlackCard } from "assets/images/icons/CardBlack.svg";
import { ReactComponent as IconBlueCard } from "assets/images/icons/CardBlue.svg";
import { ReactComponent as IconDelete } from "assets/images/icons/Delete.svg";
import { ReactComponent as IconEdit } from "assets/images/icons/Edit2.svg";
import { ReactComponent as IconEyeOpen } from "assets/images/icons/eyeopen.svg";
import { ReactComponent as IconLock } from "assets/images/icons/Lock.svg";
import { ReactComponent as IconUnlock } from "assets/images/icons/unlock.svg";
import { ButtonLoader } from "components/common/form";
import Dropdown from "utils/dropdown";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import CardDetailsModal from "./CardDetails";
import { useToggle } from "hooks/useToggle";
import CardRenameModal from "./RenameCard";
import FundCardModal from "./FundCard";
import DeleteCardModal from "./DeleteCard";
import DivInput from "components/ui/div-input";
import useToolkit from "hooks/useToolkit";
import { getCardDetails, getCardTransactions, toggleCardStatus } from "store/card/action";
import moment from "moment";
import Maybe from "components/common/Maybe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardTransactionsTable from "./revamp/CardTransactionsTable";

export const SelectedCardDetails = () => {
  const navigate = useNavigate();
  const [showCardDetails, toggleShowCardDetails] = useToggle();
  const [showCardRename, toggleShowCardRename] = useToggle();
  const [showCardFund, toggleShowCardFund] = useToggle();
  const [showCardDelete, toggleShowCardDelete] = useToggle();

  const { dispatch, useSelector, getCurrencySymbol, showValue, businessProfile } = useToolkit()
  const {
    selectedCard: activeCard,
    cardDetailsLoading,
    cardDetailsData,
    toggleCardLoading,
    cardTrxLoading,
    cardTrxData,
  } = useSelector((state: any) => state.cards);
  const transactions = cardTrxData?.data?.transactions

  const details = cardDetailsData?.data;
  const cardIsActive = details?.status === 'active';
  const fullName = `${activeCard?.customer?.firstName} ${activeCard?.customer?.lastName}`

  function goBack() {
    navigate("/cards");
  }

  const handleToggle = () => {
    const payload = {
      id: activeCard?.id,
      path: cardIsActive ? 'lock' : 'unlock'
    }

    if (!toggleCardLoading) {
      dispatch(toggleCardStatus(payload))
    }
  }


  const cardAction = [
    {
      label: "Card Label",
      onClick: toggleShowCardRename,
      icon: (
        <IconEdit className="w-8 h-8 p-1.5 bg-[#F5F5F5] text-shiga-dark rounded-full cursor-pointer outline-none" />
      ),
    },
    {
      label: "Show Details",
      onClick: toggleShowCardDetails,
      icon: (
        <IconEyeOpen className="w-8 h-8 p-1.5 bg-[#F5F5F5] text-shiga-dark rounded-full cursor-pointer outline-none" />
      ),
    },
    {
      label: toggleCardLoading ? 'Toggling' : cardIsActive ? 'Lock' : 'Unlock',
      onClick: handleToggle,
      icon: cardIsActive ? (
        <IconLock
          className="w-8 h-8 p-1.5 bg-[#F5F5F5] text-shiga-dark rounded-full cursor-pointer outline-none"
        />
      ) : (
        <IconUnlock
          className="w-8 h-8 p-1.5 bg-[#F5F5F5] text-shiga-dark rounded-full cursor-pointer outline-none"
        />
      )
    },
  ];

  useEffect(() => {
    if (!activeCard) {
      navigate('/cards')
    } else {
      dispatch(getCardDetails(activeCard?.id))
      dispatch(getCardTransactions(activeCard?.id))
    }
    // eslint-disable-next-line 
  }, [activeCard])

  return (
    <>
      <Maybe condition={cardDetailsLoading}>
        <div className="w-full">

          <div className="table-info">
            <FontAwesomeIcon icon="spinner" spin />
            <div className="font-medium">
              Fetching card details
            </div>
          </div>

        </div>
      </Maybe>

      <div className="pt-6 px-6 pb-24 sm:pt-10 sm:px-14">
        <Maybe condition={!cardDetailsLoading}>
          <div className="flex items-center justify-between mb-5 pb-5 border-b border-gray-100">
            <div
              onClick={goBack}
              className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
            >
              <IconNavigation className="w-full h-full" />
            </div>
            <Dropdown className="" dropdownClass="dropdown-right">
              <IconMore />
              <div>
                <Menu.Item
                  onClick={toggleShowCardDelete}
                  as="div"
                  className="dropdown-item dropdown-item-icon text-shiga-warn"
                >
                  <IconDelete />
                  Delete Card
                </Menu.Item>
              </div>
            </Dropdown>
          </div>
          <div className="flex flex-col md:flex-row mb-6 pb-5 border-b border-gray-100">
            <div className="sm:mr-24">
              <div className="w-[312px] h-[200px] mb-4">
                {activeCard?.currency === "USD" ? (
                  <IconBlackCard />
                ) : (
                  <IconBlueCard />
                )}
              </div>
              <div className="flex justify-between">
                {cardAction.map((item, idx) => (
                  <div
                    key={idx}
                    onClick={item.onClick}
                    className="flex flex-col items-center space-y-2"
                  >
                    {item.icon}
                    <span>{item.label}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full">
              <div className="flex justify-between pb-5 border-b border-gray-100">

                <Maybe condition={activeCard !== null}>
                  <div className="flex flex-col space-y-1">
                    <span className="text-shiga-gray">Card Balance</span>
                    <span className="text-3xl font-ojah">
                      {showValue(`${getCurrencySymbol(activeCard?.currency)} ${(details?.balance / 100)}`)}
                    </span>
                  </div>
                </Maybe>

                <div>
                  <ButtonLoader
                    onClick={toggleShowCardFund}
                    type="submit"
                    className="btn btn-lg btn-block btn-shiga-black"
                  >
                    Fund Card
                  </ButtonLoader>
                </div>
              </div>
              <div className="pt-5 grid grid-cols-1 sm:grid-cols-2 gap-x-6">
                <DivInput label="Created by" value={businessProfile?.name || ''} />
                <DivInput
                  label="Date Created"
                  value={moment(activeCard?.createdAt).format('MMM D, YYYY [at] h:mma')}
                />

                <DivInput label="Assigned to" value={fullName} capitalize />

                <DivInput label="Type" value={`${activeCard?.currency} Card`} />
              </div>
            </div>
          </div>
        </Maybe>

        <div className="mt-8">
          <CardTransactionsTable
            data={transactions}
            loading={cardTrxLoading}
            meta={cardTrxData?.data?.meta}
          />
        </div>

      </div>
      <CardDetailsModal
        isOpen={showCardDetails}
        setIsOpen={toggleShowCardDetails}
      />
      <CardRenameModal
        isOpen={showCardRename}
        setIsOpen={toggleShowCardRename}
      />
      <FundCardModal isOpen={showCardFund} setIsOpen={toggleShowCardFund} />
      <DeleteCardModal
        onDelete={() => {
          navigate("/cards");
        }}
        isOpen={showCardDelete}
        setIsOpen={toggleShowCardDelete}
      />
    </>
  );
};
