
export const RESET_CHARGEBACK_PARAMS = "RESET_CHARGEBACK_PARAMS";

export const GET_ALL_CHARGEBACKS_START = "GET_ALL_CHARGEBACKS_START";
export const GET_ALL_CHARGEBACKS_DONE = "GET_ALL_CHARGEBACKS_DONE";
export const GET_ALL_CHARGEBACKS_FAILED = "GET_ALL_CHARGEBACKS_FAILED";

export const UPDATE_CHARGEBACK_START = "UPDATE_CHARGEBACK_START";
export const UPDATE_CHARGEBACK_DONE = "UPDATE_CHARGEBACK_DONE";
export const UPDATE_CHARGEBACK_FAILED = "UPDATE_CHARGEBACK_FAILED";
