import { ChangeEvent, useEffect, useState } from "react";
import CustomAmountField from "components/custom/CustomAmountField";
import { getCurrentBalance } from "lib/business";
import { toast } from "react-toastify";
import { defaultWallet, Wallet } from "types/wallet";
import { useSharedStore } from "zustand-store";
import { ReactComponent as IconInfoFillYellow } from "assets/images/icons/Info-Yellow.svg";
import { ButtonLoader, SelectInput } from "components/common/form";
import useToolkit from "hooks/useToolkit";
import { updateChargeback } from "store/chargebacks/action";
import { RootState } from "store/store";

interface FormValues {
  amount: string;
  currency: string;
  refundType: string;
}

const initialFormValues: FormValues = {
  amount: "",
  currency: "USD",
  refundType: "",
};

const refundTypesOptions = [
  { label: "Full refund", value: "FULL" },
  { label: "Partial refund", value: "PARTIAL" },
];

type Props = {
  selected: any;
};

const AcceptChargeback = ({ selected }: Props) => {
  const { currencies } = useSharedStore();
  const [formV, setFormV] = useState<FormValues>(initialFormValues);
  const [chargebackAmount, setChargebackAmount] = useState("");
  const [currentWallet, setCurrentWallet] = useState<Wallet>(defaultWallet);
  const { dispatch, useSelector } = useToolkit()
  const { updateChargebackLoading: loading } = useSelector((state: RootState) => state.chargebacks);

  const updateAmount = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    setChargebackAmount(value);
    setFormV((prevFormV) => ({
      ...prevFormV,
      amount: value,
    }));
  };

  const updateCurrentBalance = (currency: string) => {
    getCurrentBalance(currency)
      .then((data: any) => {
        setCurrentWallet(data.data);
      })
      .catch((err) => {
        toast.error(`Unable to get balance: ${err.message}`);
      });
  };

  const inputExceedsTrxAmount =
    currentWallet?.balance !== undefined &&
    Number(chargebackAmount) > Number(selected?.amount);

  const walletString = sessionStorage.getItem("wallet");
  const wallet = JSON.parse(walletString!);

  const currencyCodesInWallet = wallet.map(
    (walletItem: any) => walletItem?.currency
  );
  const filteredCurrencies = currencies.filter((currency) =>
    currencyCodesInWallet.includes(currency.currency)
  );

  const currenciesList = filteredCurrencies.map((item: any) => ({
    label: item.currency,
    value: item.currency,
    flagSrc: `/flags/${item.currency.toLowerCase()}.svg`,
  }));


  const alreadyAccepted = selected?.status === 'ACCEPTED';
  const disableAmountEdit = formV.refundType === 'FULL' || alreadyAccepted;
  const amountMessage = `The total payment is ${chargebackAmount} USD`;
  const disableProceed = inputExceedsTrxAmount || formV.refundType === '' || alreadyAccepted;

  const onProceed = () => {
    const data = {
      type: 'accept',
      id: selected?.id,
      payload: {
        refundType: formV.refundType,
        amount: Number(formV.amount),
        currency: 'USD',
      }
    }
    dispatch(updateChargeback(data))
  }


  useEffect(() => {
    const curr = formV.currency;
    if (curr) {
      updateCurrentBalance(curr);
    }
  }, [formV.currency]);


  useEffect(() => {

    if (selected !== null) {
      setChargebackAmount(selected?.amount);
      setFormV((prevFormV) => ({
        ...prevFormV,
        amount: selected?.amount,
      }));
    }

  }, [selected])

  return (
    <div className="pb-12 mb-16 ">
      <div className=" rounded-3xl bg-gradient-to-t from-[#FFFBF5] to-[#FFFBF5] bg-[#FFF7EB] flex flex-col items-start gap-4 p-4 md:p-6 w-full">
        <div className="flex items-start gap-x-2">
          <IconInfoFillYellow className="flex-none w-[12px] h-[12px] mt-1" />
          <p className="font-inter text-[#523000] text-[12px]  tracking-tighter">
            By accepting this chargeback, you’re instructing us to process a
            refund that will be deducted from your next payout
          </p>
        </div>

      </div>
      <div>
        <div className=" mb-[24px] z-10">
          <SelectInput
            required
            label="How much should we refund?"
            value={formV.refundType}
            onChange={(e: any) => {
              setFormV({ ...formV, refundType: e.target.value });
            }}>
            <option value="">Select an option</option>
            {refundTypesOptions?.map((item) => {
              return (
                <option value={item?.value} key={item?.label}>
                  {item.label}
                </option>
              );
            })}
          </SelectInput>
        </div>
        <div className="relative z-0">
          <CustomAmountField
            disabled={disableAmountEdit}
            leftLabel="You send"
            isFieldRequired
            setInputAmount={updateAmount}
            inputError={inputExceedsTrxAmount}
            errorText={
              inputExceedsTrxAmount
                ? `Refund amount cannot exceed ${selected?.amount} USD`
                : amountMessage
            }
            flagIconUrl={
              currencies.find((curr) => curr.currency === 'USD')
                ?.icon || ""
            }
            dropdownCurrencies={currenciesList}
            inputAmount={formV.amount}
            transactionCurrency='USD'
            rightLabel={
              <p className="text-shiga-dark flex items-center font-inter">
                You have &nbsp;
                <span className="text-shiga-purple font-medium">
                  {`${Number(currentWallet?.balance).toLocaleString()} ${currentWallet?.currency ?? ""
                    }`}
                </span>
              </p>
            }
          />
        </div>

        <div className="flex items-end justify-end ">
          <ButtonLoader
            type="button"
            onClick={onProceed}
            loading={loading}
            disabled={disableProceed}
            className="btn btn-lg btn-block btn-primary mt-6 bg-shiga-green-300 w-auto disabled:bg-opacity-50 disabled:cursor-not-allowed">
            {alreadyAccepted ? 'Chargeback already accepted' : 'Accept Chargeback'}
          </ButtonLoader>
        </div>
      </div>
    </div>
  );
};

export default AcceptChargeback;
