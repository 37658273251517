import { NavLink, useLocation } from "react-router-dom";

interface EcommerceNavItem {
  label: string;
  url: `/ojah/${string}`;
  tag: string;
}

const navItems: EcommerceNavItem[] = [
  { label: "Products", url: "/ojah/products", tag: "products" },
  { label: "Orders", url: "/ojah/orders", tag: "orders" },
  // { label: "Delivery Agents", url: "/ojah/delivery-agent", tag: "delivery-agent" },
];

const EcommerceNav = () => {
  const { pathname } = useLocation();

  return (
    <div className="w-[260px] border-r border-outline-grey py-11 pl-5">
      <ul>
        {navItems.map((item, index) => (
          <NavItem key={index} isActive={pathname?.includes(item.tag)} url={item.url}>
            {item.label}
          </NavItem>
        ))}
      </ul>
    </div>
  );
};

interface NavItemProps {
  url: `/ojah/${string}`;
  isActive: boolean;
  children: React.ReactNode;
}

const NavItem = ({ children, isActive, url }: NavItemProps) => {
  return (
    <NavLink
      to={url}
      className={`${isActive ? "border-r-[6px] border-shiga-purple-2 bg-shiga-purple-accent rounded-s-md text-shiga-black" : "text-shiga-dark"} block px-2 py-[9px] font-normal text-base`}
    >
      {children}
    </NavLink>
  );
};

export default EcommerceNav;
