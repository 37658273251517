import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ReactComponent as IconOjah } from "assets/images/icons/ojah-logo.svg";
import useToolkit from "hooks/useToolkit";
import { closeProductEditModal } from "store/ecom/action";

type Props = {
  setSessionModalOpen: React.Dispatch<boolean>;
};

const LayoutHeader = ({ setSessionModalOpen }: Props) => {
   const { dispatch } = useToolkit();

  return (
    <div className="px-6 lg:px-9 py-6 flex items-center justify-between border-b border-gray-100 bg-white z-20 fixed w-full top-0">
      <IconOjah />

      <button
        onClick={() => {
          setSessionModalOpen(false);
          dispatch(closeProductEditModal());
        }}
        className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
      >
        <IconClose className="m-auto text-white svg-stroke-gray" />
      </button>
    </div>
  );
};

export default LayoutHeader;