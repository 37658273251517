import { useOutletContext } from "react-router-dom";
import ProductTable from "./table/ProductTable";
import { Dispatch, SetStateAction, useEffect } from "react";
import useToolkit from "hooks/useToolkit";
import ShigaButton from "components/common/form/ShigaButton";
import { RootState } from "store/store";
import { closeProductEditModal } from "store/ecom/action";

interface ContextType {
  setCreateProductOpen: Dispatch<SetStateAction<boolean>>;
  setLogoModalShown: Dispatch<SetStateAction<boolean>>;
  toggleTermsModal: () => void;
}

const Products = () => {
  const { dispatch, businessProfile, useSelector, refetchProfiles, isVendorBusiness } = useToolkit();
  const { setCreateProductOpen, toggleTermsModal, setLogoModalShown } = useOutletContext<ContextType>()
  const { deleteProductLoading, getProductsLoading } = useSelector((state: RootState) => state.ecom)

 

  const isValidBusinessLogo =
    businessProfile?.logo &&
    businessProfile?.logo?.length > 0 &&
    businessProfile?.logo?.includes("https://");

  useEffect(() => {
    refetchProfiles();
    // eslint-disable-next-line
  }, []);

  const handleAddProduct = () => {
    if (isVendorBusiness) {
      if (isValidBusinessLogo) {
        dispatch(closeProductEditModal())
        setCreateProductOpen(true);
        // setOpenCreateProductModal(true);
        // console.log("openCreateProductModal", openCreateProductModal);
      } else {
        setLogoModalShown(true);
      }
    } else {
      toggleTermsModal();
    }
  };

  return (
    <div>
      <div className="flex items-center lg:justify-between pb-12 mb-12 border-b border-gray-100 flex-wrap lg:flex-nowrap">
        <div className="page-title !text-3xl font-ojah font-bold border-gray-100">
          Products
        </div>

        <div className="w-full lg:w-max pt-5 lg:pt-0">
          <ShigaButton
            darkBg
            onClick={handleAddProduct}
            disabled={deleteProductLoading || getProductsLoading}
            text={isVendorBusiness ? "Add a Product" : "Onboard as vendor"}
          />
        </div>
      </div>

      <ProductTable setCreateProductOpen={setCreateProductOpen} />
    </div>
  );
};

export interface ProductType {
  id: string | number;
  name: string;
  price: number | string;
  currency: string;
  totalItem: number;
  itemSold: number;
  status: string;
  dateCreated: string;
  description: string;
  category: string;
}

export default Products;
