import { useEffect } from "react";
import { NoRefund } from "./NoRefund";
import { RefundsTable } from "./RefundsTable";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getRefundsStore } from "store/refund/reducer";
import { getRefunds } from "store/refund/action";
import useToolkit from "hooks/useToolkit";
import Maybe from "components/common/Maybe";
import { ChargebacksTable } from "./chargebacks/ChargebacksTable";

export const Refund = () => {
  const dispatch = useAppDispatch();
  const { refunds } = useAppSelector(getRefundsStore);
  const { useState, isLocalEnv } = useToolkit();
  const [activeTab, setActiveTab] = useState("refunds");

  const isRefunds = activeTab === "refunds";
  const isChargebacks = activeTab === "chargebacks";

  const tabs = [
    { title: "Refunds", value: "refunds" },
    { title: "Chargebacks", value: "chargebacks" },
  ];

  useEffect(() => {
    dispatch(getRefunds());
  }, []);

  return (
    <div className="pt-6 px-6 pb-24 sm:pt-10 sm:px-14">
      {/* <div className="flex items-center justify-between pb-5 mb-5 border-b border-gray-100">
        <div className="page-title !text-3xl font-ojah font-bold border-gray-100">
          Refunds
        </div>
      </div> */}

      <div className="w-full flex items-center space-x-7 pb-6 border-b border-shiga-gray-75 mb-5">
        {tabs.map((item: any) => {
          const isActive = activeTab === item?.value;
          const isChargebacksDisabled =
            item?.value === "chargebacks" && !isLocalEnv;

          return (
            <button
              key={item?.value}
              onClick={() => setActiveTab(item.value)}
              className={`${item?.value === "chargebacks" && isChargebacksDisabled
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
                } active:scale-90 transition-transform font-ojah 
                        ${isActive
                  ? "bg-shiga-dark-100 py-2 px-3.5 rounded-full text-white"
                  : "text-shiga-gray-100"
                }`}
              disabled={isChargebacksDisabled}
            >
              {item?.title}
            </button>
          );
        })}
      </div>

      <Maybe condition={isRefunds}>
        {refunds?.data.payload.length ? (
          <RefundsTable data={refunds?.data} refunds={refunds.data.payload} />
        ) : (
          <NoRefund />
        )}
      </Maybe>
      <Maybe condition={isChargebacks}>
        <ChargebacksTable />
      </Maybe>
    </div>
  );
};
