import { ReactComponent as FileType } from "assets/images/icons/FileType.svg";
import { ReactComponent as IconAdd } from "assets/images/icons/Add.svg";
import { ReactComponent as IconEdit2 } from "assets/images/icons/Edit2.svg";
import { ReactComponent as IconReveal } from "assets/images/icons/Reveal.svg";
import { ReactComponent as IconDelete } from "assets/images/icons/Delete.svg";
import { ReactComponent as IconUserProfile } from "assets/images/icons/user-profile.svg";
import Dropdown from "utils/dropdown";
import { Menu } from "@headlessui/react";
import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "components/data-table/data-table-column-header";
import { useCardStore } from "zustand-store/cards";
import { Card, defaultCard } from "types/card";
import moment from "moment";
const showValue = (value: any) => value || 'N/A';


const CardDropdown: React.FC<{
    handleFund: () => void;
    handleRename: () => void;
    handleDetails: () => void;
    handleDelete: () => void;
}> = ({ handleDelete, handleDetails, handleFund, handleRename }) => {

    return (
        <Dropdown dropdownClass="dropdown-right">
            <></>
            <div>
                <Menu.Item
                    as="div"
                    onClick={handleFund}
                    className="dropdown-item dropdown-item-icon"
                >
                    <IconAdd />
                    Fund this card
                </Menu.Item>
                <Menu.Item
                    as="div"
                    onClick={handleRename}
                    className="dropdown-item dropdown-item-icon"
                >
                    <IconEdit2 />
                    Rename Card
                </Menu.Item>
                <Menu.Item
                    as="div"
                    onClick={handleDetails}
                    className="dropdown-item dropdown-item-icon"
                >
                    <IconReveal />
                    Show Card Details
                </Menu.Item>
                <Menu.Item
                    as="div"
                    onClick={handleDelete}
                    className="dropdown-item dropdown-item-icon text-shiga-warn"
                >
                    <IconDelete />
                    Delete Card
                </Menu.Item>
            </div>
        </Dropdown>
    )
}

export const CardColumns: ColumnDef<Card>[] = [
    {
        accessorKey: "Card",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="CARD" />
        ),
        cell: ({ row: { original }, table }) => {

            // const labelIsValid = original?.label !== undefined && original?.label?.length > 0;

            return (
                <div className="flex items-center cursor-pointer" onClick={(table.options as any).meta?.handleSelected}>
                    <div className="w-8 h-9 mr-3">
                        <FileType className="m-auto" />
                    </div>
                    <div>
                        <div className="font-medium">{showValue(original?.label)}</div>
                        <div className="text-sm text-shiga-gray">{showValue(`**** ${original?.last4}`)}</div>
                    </div>
                </div>
            )
        },
    },
    {
        accessorKey: "assigned",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="ASSIGNED TO" />
        ),
        cell: ({ row, table }) => {

            const fullName = `${row.original?.customer?.firstName} ${row.original?.customer?.lastName}`

            return (
                <div className="flex items-center cursor-pointer" onClick={(table.options as any).meta?.handleSelected}>
                    <div className="w-7 h-7 mr-3 bg-gray-200 rounded-md">
                        <IconUserProfile className="w-full h-full" />
                    </div>

                    <div className="capitalize">
                        {showValue(fullName)}
                    </div>
                </div>
            )
        },
    },
    // {
    //     accessorKey: "balance",
    //     header: ({ column }) => (
    //         <DataTableColumnHeader column={column} title="BALANCE" />
    //     ),
    //     cell: ({ row, table }) => {
    //         return <span className="cursor-pointer" onClick={(table.options as any).meta?.handleSelected}>
    //             {showValue(row?.original?.balance)}
    //         </span>
    //     },
    // },
    {
        accessorKey: "status",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="STATUS" />
        ),
        cell: ({ row, table }) => {
            const isActive = row.original?.status === 'ACTIVE'

            return (
                <>
                    <div className={`label-border cursor-pointer ${isActive ? 'text-[#00C46C] border-[#00C46C]' : ''}`}
                        onClick={(table.options as any).meta?.handleSelected}>
                        {isActive ? 'Active' : row.original?.status}
                    </div>
                    {/* <Maybe condition={index < 2}>
                </Maybe> */}
                    {/* <Maybe condition={index >= 2}>
                    <div className="label-border !text-[#F29100] !border-outline-orange">
                        Locked
                    </div>
                </Maybe> */}
                </>
            )
        },
    },
    {
        accessorKey: "date_created",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="DATE CREATED" />
        ),
        cell: ({ row, table }) => <span className="font-medium cursor-pointer"
            onClick={(table.options as any).meta?.handleSelected}>
            {moment(row.original?.createdAt).format('MMM D, YYYY [at] h:mma')}
        </span>,
    },
    {
        accessorKey: "dropdown",
        header: ({ column }) => (<></>),
        cell: ({ row, table, getValue }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { setCurrentSelectedCard } = useCardStore();

            const handleGetCardInfo = () => {
                // getCard(row.original.id).then((data: any) => {
                setCurrentSelectedCard({
                    ...defaultCard,
                    name: "Netflix account",
                })
                // })
            }

            const handleDelete = () => {
                if ((table.options as any).meta?.handleShowDelete) {
                    handleGetCardInfo();
                    (table.options as any).meta?.handleShowDelete("")
                }
            }
            const handleFund = () => {
                if ((table.options as any).meta?.handleFund) {
                    handleGetCardInfo();
                    (table.options as any).meta?.handleFund("")
                }
            }
            const handleDetails = () => {
                handleGetCardInfo();
                (table.options as any).meta?.handleShowDetails("")
            }
            const handleRename = () => {
                handleGetCardInfo();
                (table.options as any).meta?.handleRename("")
            }
            return (
                <CardDropdown {...{ handleDelete, handleDetails, handleFund, handleRename }} />
            )
        },
    },
];
