import { useEffect, useState } from 'react';
import LayoutFlowWrapper from './layout/LayoutFlowWrapper';
import useEcomProvider from './context/useEcomProvider';
import Maybe from 'components/common/Maybe';
import ProductImages from './product/ProductImages';
import ProductDetails from './product/ProductDetails';
import EditProductImages from './product/edit/EditProductImages';
import EditProductDetails from './product/edit/EditProductDetails';

const SessionLandingPage = () => {
   const { sessionStep, isEditingProduct } = useEcomProvider()
   const [header, setheader] = useState({ title: '', subtitle: '' });

   const stepOneTitle = `${isEditingProduct ? 'Edit' : 'Add'} Images for this product`;
   const stepTwoTitle = `${isEditingProduct ? 'Edit' : 'Add'} Details for this product`;

   useEffect(() => {
      if (sessionStep === 1) {
         setheader({
            title: stepOneTitle,
            subtitle: "Upload high quality images that can sell this product on Ojah's marketplace."
         });
      } else {
         setheader({
            title: stepTwoTitle,
            subtitle: 'Provide more information about this product'
         });
      }
      // eslint-disable-next-line
   }, [sessionStep])


   return (
      <LayoutFlowWrapper
         title={header.title}
         subtitle={header.subtitle}>

         <Maybe condition={sessionStep === 1}>

            <Maybe condition={isEditingProduct === true}>
               <EditProductImages />
            </Maybe>

            <Maybe condition={isEditingProduct === false}>
               <ProductImages />
            </Maybe>

         </Maybe>

         <Maybe condition={sessionStep === 2}>

            <Maybe condition={isEditingProduct === true}>
               <EditProductDetails />
            </Maybe>

            <Maybe condition={isEditingProduct === false}>
               <ProductDetails />
            </Maybe>

         </Maybe>

      </LayoutFlowWrapper>
   )
}

export default SessionLandingPage