import Maybe from "components/common/Maybe";
import useToolkit from "hooks/useToolkit";
import AcceptChargeback from "./AcceptChargeback";
import DeclineChargeback from "./DeclineChargeback";

type Props = {
  selected: any;
};

const ResolveChargeback = ({ selected }: Props) => {
  const { useState, isLocalEnv } = useToolkit();
  const [activeTab, setActiveTab] = useState("accept");

  const isAccept = activeTab === "accept";
  const isReject = activeTab === "reject";

  const tabs = [
    { title: "Accept Chargeback", value: "accept" },
    { title: "Decline Chargeback", value: "reject" },
  ];
  return (
    <div className="md:w-[448px] h-full mx-auto flex flex-col ">
      <div className="w-full flex items-center space-x-4 sticky top-0 z-10  border-b border-shiga-gray-75 ">
        {tabs.map((item: any) => {
          const isActive = activeTab === item?.value;

          return (
            <button
              key={item?.value}
              onClick={() => (isLocalEnv ? setActiveTab(item.value) : null)}
              className={`relative cursor-pointer active:scale-90 transition-transform font-ojah font-bold pt-2 px-3.5 text-2xl
                        ${isActive ? "text-shiga-black" : "text-shiga-gray-100"}`}>
              {item?.title}
              <div
                className={`h-[2px] mt-4 
                ${isActive ? "bg-shiga-black w-full " : "text-shiga-gray-100"}`}
              ></div>
            </button>
          );
        })}
      </div>

      <div className="flex-grow overflow-y-auto scrollbar-hide">
        <Maybe condition={isAccept}>
          <div className="mt-8">
            <AcceptChargeback selected={selected} />
          </div>
        </Maybe>
        <Maybe condition={isReject}>
          <DeclineChargeback selected={selected} />
        </Maybe>
      </div>
    </div>
  );
};

export default ResolveChargeback;
