import { Menu } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonLoader } from "components/common/form";
import Dropdown from "utils/dropdown";
import { ActionOption } from "../interface";
import useToolkit from "hooks/useToolkit";
import Maybe from "components/common/Maybe";

export const DashboardHeader: React.FC<{
  handleAddMoney: () => void;
  handleTransfer: () => void;
  updateActionOptions: (selected: string) => void
  actionOptions: ActionOption[];
  activeCurrency: string;
}> = ({ actionOptions, handleAddMoney, handleTransfer, updateActionOptions, activeCurrency }) => {
  const { kybVerified, isVendorBusiness } = useToolkit();
  const isVendorNgnPayout = isVendorBusiness === true && activeCurrency === 'NGN';

  return (
    <div className="mt-2 lg:mt-0 lg:flex lg:justify-end">
      <div className="w-max p-1 flex gap-5">

        <ButtonLoader
          disabled={!kybVerified}
          type="button"
          onClick={handleAddMoney}
          loading={false}
          className="btn bg-shiga-purple text-white disabled:!cursor-not-allowed">
          <span>Add Money</span>
        </ButtonLoader>

        <ButtonLoader
          disabled={!kybVerified && !isVendorNgnPayout}
          type="button"
          onClick={handleTransfer}
          loading={false}
          className="btn border border-outline-grey-2 disabled:!cursor-not-allowed">
          <span>Send Money</span>
        </ButtonLoader>

        <Maybe condition={kybVerified}>
          <Dropdown dropdownClass="min-w-[150px] dropdown-right">

            <ButtonLoader
              type="button"
              loading={false}
              className="btn border border-outline-grey-2">
              <FontAwesomeIcon
                icon="ellipsis-vertical"
                className="sizee-6"
              />
            </ButtonLoader>


            <div>
              {actionOptions.map((action) => (
                <Menu.Item key={action.value}>
                  {({ active }) => (
                    <div
                      onClick={() => updateActionOptions(action.value)}
                      className={`${active && "bg-[#444444]"
                        } dropdown-item flex items-center gap-3`}
                    >
                      <action.icon />

                      <div className="space-y-1">
                        <h5 className="whitespace-nowrap font-medium text-[16px]">
                          {action.label}
                        </h5>
                        <p className="text-shiga-dark text-[13px] tracking-tight">
                          {action.desc}
                        </p>
                      </div>
                    </div>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Dropdown>
        </Maybe>
      </div>
    </div>
  )
}