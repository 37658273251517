import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthPageTitle from "components/common/AuthPageTitle";
import validate from "utils/validate";
import { ButtonLoader, FileUploadForm, Input, SelectInput } from "components/common/form";
import { ReactComponent as IconArrowLeft } from "assets/images/icons/ArrowLeft.svg";
import { ReactComponent as IconTick } from "assets/images/icons/Check-Circle.svg";
import { ReactComponent as IconTickGray } from "assets/images/icons/check-gray.svg";
import { meansOfIdentication } from "data/constants/common";
import PinModal from "components/auth/PinModal";
import useLoginInfo from "hooks/useLoginInfo";
import useMediaService from "hooks/useMediaService";
import { toast } from "react-toastify";
import moment from "moment";
import { useSharedStore } from "zustand-store";
import Maybe from "components/common/Maybe";
import * as businessVerificationActions from "store/entities/businessVerification/action";
import * as userActions from "store/auth/user/action";
import * as countriesActions from "store/ui/countries/action";
import useToolkit from "hooks/useToolkit";
import { AddressField, googleAddressPayload } from "components/custom/GoogleAddressField";
import { initiateBvnVerification, resetBvn, resetNIn, validateBvn } from "store/entities/settings/action";
import ShigaButton from "components/common/form/ShigaButton";


const OwnerInformation = (props: any) => {
    const { goPrevious, goNext } = props;
    const dispatch = useDispatch();
    const { userInfo } = useLoginInfo();
    const { uploadFile, uploadedFileLoading } = useMediaService();
    const { countriesLoading, countries } = useSelector((s: any) => s.ui.countries);
    const { businessVerificationUpdateLoading, businessVerificationUpdate } = useSelector((s: any) => s.entities.businessVerification);
    const { otpEmailSendLoading, otpEmailSend, otpEmailResendLoading, otpEmailResend, otpEmailVerificationLoading, otpEmailVerification } = useSelector((s: any) => s.auth.user);
    const { userProfile, businessProfile, toastError } = useToolkit();
    const [location, setLocation] = useState(businessProfile?.address || '');
    const [idLabel, setIdLabel] = useState('');

    const [isFormValidated, setIsFormValidated] = useState(false);
    const [isOpenOTP, setIsOpenOTP] = useState(false);
    const [otp, setOtp] = useState("");
    const { currencies } = useSharedStore();
    const [form, setForm] = useState({
        phoneNumber: "",
        country: "",
        dateOfBirth: "",
        address: "",
        meansOfIdentification: "",
        bvn: "",
        identityDocument: "",
        idNumber: "",
    });
    const [bvnValidated, setBvnValidated] = useState(false);
    // eslint-disable-next-line
    const [ninValidated, setNinValidated] = useState(false);
    const { bvnData, bvnLoading, ninData, ninLoading, bvnAuthLoading, bvnAuthData, bvnValidateLoading, bvnValidateData } = useSelector((s: any) => s.entities.settings);
    const verifyLoading = bvnLoading || ninLoading || bvnAuthLoading || bvnValidateLoading;
    const { BVN, ...otherIdTypes } = meansOfIdentication;
    const [bvnAuthFieldShown, setBvnAuthFieldShown] = useState(false);
    const [bvnOtp, setBvnOtp] = useState('');

    const ownerIsNigerian = form.country === "NG";
    const isNinId = form.meansOfIdentification === 'NIN';
    const isLicenseID = form.meansOfIdentification === 'DRIVER_LICENSE';
    const isPvcID = form.meansOfIdentification === 'PERMANENT_VOTERS_CARD';
    const isPassportID = form.meansOfIdentification === 'PASSPORT';
    const bvnIsFilled = businessProfile?.bvnId && businessProfile?.bvnId?.length > 0 ? true : false;
    const bvnValidationComplete = (ownerIsNigerian && bvnValidateData?.success === true) || bvnIsFilled;

    const ownersArray = businessProfile?.directors?.filter((director: any) => director?.type === 'OWNER')
    const recentOwnerInfo = ownersArray !== undefined ? ownersArray[ownersArray.length - 1] : null;
    const ownersIdType = recentOwnerInfo?.idType;
    const ownersIdTypeValid = ownersIdType?.length > 0;
    const ownerIdDoc = recentOwnerInfo?.document;

    const populateForm = () => {
        setForm({
            phoneNumber: businessProfile?.phoneNumber ?? "",
            country: userProfile?.country ?? "",
            dateOfBirth: recentOwnerInfo?.dob ?? "",
            address: userInfo?.userProfile?.address || "",
            meansOfIdentification: userProfile?.idType ?? "",
            bvn: userProfile?.bvn ?? "",
            identityDocument: ownersIdTypeValid ? ownerIdDoc : userProfile?.document || "",
            idNumber: recentOwnerInfo?.document || userProfile?.document || "",
        });
    };

    const getCountries = () => {
        if (!countriesLoading && (Object.keys(countries).length === 0 || countries?.success === false)) {
            dispatch(countriesActions.getCountries());
        }
    };

    const checkFormValidation = (data: any = form, appendKey: any, appendValue: any) => {
        let params = { ...data };
        if (appendKey) {
            params = {
                ...params,
                [appendKey]: appendValue,
            };
        }
        const errors = validate(params);
        if (errors) {
            setIsFormValidated(false);
        } else {
            setIsFormValidated(true);
        }
    };

    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();

        const bvnValid = bvnIsFilled || (userProfile?.bvn !== undefined && userProfile?.bvn?.length > 0);
        const ninValid = userProfile?.nin !== undefined && userProfile?.nin?.length > 0;

        const formCopy: any = { ...form };

        delete formCopy.idNumber;
        if (businessProfile?.address?.length) {
            delete formCopy.address
        };

        if (bvnValid || ownerIsNigerian === false) delete formCopy.bvn;

        if (ownersIdTypeValid || (bvnValid === true && ninValid === true)) {
            delete formCopy.identityDocument;
        }

        if (ownersIdTypeValid) {
            delete formCopy.meansOfIdentification;
        }

        const errors: any = validate(formCopy);

        if (errors) {
            for (var x in errors) {
                toast.error(errors[x]);
            }
            return;
        } else if (form.idNumber === '' && !ownersIdTypeValid) {
            toastError(`Please enter a valid ${idLabel}`)
        } else if (form?.dateOfBirth?.includes('Invalid')) {
            toastError('Please select a valid date of birth')
        } else {

            processFormSubmission();

            // if (isFormValidated === true) {
            //     const params = {
            //         identifier: form.phoneNumber,
            //         type: "SMS",
            //     };
            //     dispatch(userActions.sendOtp(params));
            // }
        }
    };
    const processFormSubmission = async () => {
        let params: any = {
            account: {
                address: googleAddressPayload.description || form.address,
                country: form.country,
                dob: form.dateOfBirth,
                phoneNumber: form.phoneNumber,
            },
            bvn: form.bvn,
            idNumber: form.idNumber,
            idType: ownersIdTypeValid ? ownersIdType : form.meansOfIdentification,
            document: ownersIdTypeValid ? ownerIdDoc : ""
        };

        if (typeof form.identityDocument !== "string") {
            const response: any = await uploadFile(form.identityDocument);
            if (response?.success === true) {
                params = { ...params, document: response?.data?.file?.link };
            }
        }
        dispatch(businessVerificationActions.updateBusinessOwner(params));
    };

    const resendOtp = () => {
        if (isFormValidated === true) {
            const params = {
                identifier: form.phoneNumber,
                type: "SMS",
            };
            dispatch(userActions.resendOtp(params));
        }
    };

    const submitOtp = () => {
        const errors: any = validate({ otp });
        if (errors) {
            for (var x in errors) {
                toast.error(errors[x]);
            }
            return;
        } else {
            // const params = {
            //     code: otp,
            //     identifier: userProfile?.email || '',
            // };
            const params = {
                code: otp,
                identifier: form.phoneNumber,
            };
            dispatch(userActions.verifyOtp(params));
        }
    };

    const handleBvnVerification = () => {
        const payload = {
            identityId: bvnAuthData?.data?.identityId,
            type: 'BVN',
            otp: bvnOtp
        }
        dispatch(validateBvn(payload))
    }

    useEffect(() => {
        getCountries();
        dispatch(resetBvn());
        dispatch(resetNIn());
        setBvnValidated(false);
        setBvnAuthFieldShown(false);
        setBvnOtp('');
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (userInfo?.businessProfile !== undefined) {
            populateForm();
        }
        // eslint-disable-next-line
    }, [userInfo]);

    useEffect(() => {
        if (otpEmailSend?.success === true) {
            setIsOpenOTP(true);
            dispatch(userActions.resetSendOtp());
        }
        // eslint-disable-next-line
    }, [otpEmailSend]);

    useEffect(() => {
        if (otpEmailResend?.success === true) {
            dispatch(userActions.resetResendOtp());
        }
        // eslint-disable-next-line
    }, [otpEmailSend]);

    useEffect(() => {
        if (otpEmailVerification?.success === true) {
            dispatch(userActions.resetVerifyOtp());
            processFormSubmission();
        }
        // eslint-disable-next-line
    }, [otpEmailVerification]);

    useEffect(() => {
        if (businessVerificationUpdate?.success === true) {
            dispatch(userActions.updateUserInfo({
                businessProfile: { ...businessVerificationUpdate?.data?.business ?? {} },
            }));
            dispatch(businessVerificationActions.resetUpdateBusinessVerification());
            goNext();
        }
        // eslint-disable-next-line
    }, [businessVerificationUpdate]);


    useEffect(() => {
        if (form.bvn?.length === 11) {
            dispatch(initiateBvnVerification({ number: form.bvn, type: 'BVN' }))
        }
        // eslint-disable-next-line
    }, [form.bvn]);


    useEffect(() => {
        if (form.bvn?.length === 11 && bvnData?.data?.status === true) {
            setBvnValidated(true);
        }
        // eslint-disable-next-line
    }, [bvnData]);

    useEffect(() => {
        if (ninData?.data?.status === true) {
            setNinValidated(true);
        }
        // eslint-disable-next-line
    }, [ninData]);

    useEffect(() => {
        if (form.meansOfIdentification !== '') {
            if (isNinId) {
                setIdLabel('NIN Number')
            } else if (isPassportID) {
                setIdLabel('Passport Number')
            } else if (isLicenseID) {
                setIdLabel("Driver's License Number")
            } else if (isPvcID) {
                setIdLabel("Permanent Voter's Card Number")
            }
        }
        // eslint-disable-next-line
    }, [form.meansOfIdentification]);

    useEffect(() => {
        if (bvnAuthData?.success === true) {
            setBvnAuthFieldShown(true);
        }
        // eslint-disable-next-line
    }, [bvnAuthData]);

    return (ownersIdTypeValid && !userInfo?.businessProfile?.address?.length) ? <div>Loading...</div> : (

        <div>
            <AuthPageTitle
                title={`Let’s know more about ${userInfo?.userProfile?.firstName ?? "-"} ${userInfo?.userProfile?.lastName}`}
            />

            <PinModal
                target="OTP"
                title="Confirm your Phone"
                pin={(e: any) => setOtp(e)}
                submit={submitOtp}
                submitText="Confirm your Phone"
                showResendEmail={true}
                resendEmailClick={resendOtp}
                resendEmailLoading={otpEmailResendLoading}
                resendEmailResponse={otpEmailResend}
                loading={otpEmailVerificationLoading || uploadedFileLoading || businessVerificationUpdateLoading}
                modalLoading={false}
                isOpenPin={isOpenOTP}
                setIsOpenPin={setIsOpenOTP}
            />

            <div className="pt-10 mt-10 border-t border-gray-100">
                <form onSubmit={submitForm}>

                    <Input
                        type="tel"
                        maxLength={13}
                        label="Business Phone number"
                        placeholder="Phone number"
                        required={!businessProfile?.phoneNumber?.length}
                        value={form.phoneNumber}
                        onChange={(e: any) => {
                            setForm({ ...form, phoneNumber: e.target.value });
                            checkFormValidation(form, "phoneNumber", e.target.value);
                        }}
                        validationName="phoneNumber"
                        checkFormValidation={checkFormValidation}
                    />

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6">
                        <Maybe condition={countriesLoading === true}>
                            <Input
                                type="text"
                                label="Nationality"
                                value="Loading countries..."
                                required={true}
                                disabled={true}
                            />
                        </Maybe>
                        <Maybe condition={countriesLoading === false}>
                            <SelectInput
                                label="Nationality"
                                required={!userProfile?.country}
                                value={form.country}
                                onChange={(e: any) => {
                                    setForm({ ...form, country: e.target.value });
                                    checkFormValidation(form, "country", e.target.value);
                                }}
                                validationName="country"
                                checkFormValidation={checkFormValidation}>
                                <option value="" disabled>Select a Country</option>
                                {currencies.map((curr) => (
                                    <option value={curr.countryCode} key={curr.countryCode}>
                                        {curr.country}
                                    </option>
                                ))}
                            </SelectInput>
                        </Maybe>

                        <Input
                            type="date"
                            label="Date of Birth"
                            placeholder="DD/MM/YY"
                            required={!recentOwnerInfo?.dob?.length}
                            value={form.dateOfBirth || ''}
                            max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                            onChange={(e: any) => {
                                setForm({ ...form, dateOfBirth: e.target.value });
                                checkFormValidation(form, "dateOfBirth", e.target.value);
                            }}
                            validationName="dateOfBirth"
                            checkFormValidation={checkFormValidation}
                        />
                    </div>


                    <AddressField
                        addressValue={location || businessProfile?.address || ''}
                        inputLabel="Your address"
                        setAddressValue={setLocation}
                        isRequired={!businessProfile?.address?.length}
                    />

                    <SelectInput
                        label="Means of identification"
                        required={!ownersIdTypeValid}
                        value={form.meansOfIdentification}
                        onChange={(e: any) => {
                            setForm({ ...form, meansOfIdentification: e.target.value });
                            checkFormValidation(form, "meansOfIdentification", e.target.value);
                        }}
                        validationName="meansOfIdentification"
                        checkFormValidation={checkFormValidation}>

                        <option value={ownersIdTypeValid ? ownersIdType : ""} disabled>{recentOwnerInfo?.idType || 'Select a Document'}</option>
                        {Object.keys(otherIdTypes)?.filter((item: any) => item !== ownersIdType)?.map((key) => (
                            <option value={key} key={key}>
                                {meansOfIdentication[key]}
                            </option>
                        ))}
                    </SelectInput>

                    <Maybe condition={ownerIsNigerian}>
                        <>
                            <Input
                                type="number"
                                maxLength={11}
                                disabled={bvnIsFilled}
                                label="Bank verification number"
                                placeholder="Bank Verification Number"
                                required={ownerIsNigerian && !bvnIsFilled}
                                value={bvnIsFilled ? 'BVN already validated' : form.bvn}
                                onChange={(e: any) => {
                                    setBvnOtp('')
                                    setBvnAuthFieldShown(false);
                                    dispatch(resetBvn())
                                    setForm({ ...form, bvn: e.target.value });
                                    checkFormValidation(form, "bvn", e.target.value);
                                    setBvnValidated(false);
                                }}
                                validationName="bvn"
                                checkFormValidation={checkFormValidation}
                                rightIcon={bvnValidated && <IconTick className="h-4 w-4" />}
                            />

                            <Maybe condition={bvnAuthFieldShown}>
                                <Input
                                    type="number"
                                    maxLength={6}
                                    value={bvnOtp}
                                    required={ownerIsNigerian}
                                    disabled={bvnValidationComplete}
                                    label={`Enter the OTP sent to your BVN phone number, or dial ${bvnAuthData?.data?.message}.`}
                                    placeholder="Enter OTP to validate BVN"
                                    onChange={(e: any) => setBvnOtp(e.target.value)}
                                    rightIcon={bvnValidationComplete ?
                                        <div className="flex items-center text-shiga-gray-200">
                                            <IconTickGray width={15} />
                                            <span className="ml-1.5 font-medium">Validated</span>
                                        </div>
                                        :
                                        <ShigaButton
                                            darkBg
                                            type="button"
                                            className="!py-1.5 !rounded-lg !bg-transparent"
                                            loading={bvnValidateLoading}
                                            onClick={handleBvnVerification}
                                            disabled={bvnOtp?.length < 6 || bvnValidateLoading || bvnValidationComplete}
                                            text={bvnValidateLoading ? "Validating" : bvnValidationComplete ? "Validated" : "Validate"}
                                        />
                                    }
                                />
                            </Maybe>
                        </>
                    </Maybe>

                    <Maybe condition={form.meansOfIdentification !== ''}>
                        <Input
                            type="text"
                            label={idLabel}
                            placeholder={"Enter " + idLabel}
                            required={true}
                            value={form.idNumber}
                            onChange={(e: any) => {
                                setForm({ ...form, idNumber: e.target.value });
                            }}
                        />
                    </Maybe>

                    <div className="my-6" />

                    <FileUploadForm
                        label="Upload Identification document"
                        required={!ownersIdTypeValid}
                        data={form.identityDocument}
                        onchange={(e: any) => {
                            if (e === null) {
                                setForm({ ...form, identityDocument: "" });
                            } else {
                                setForm({ ...form, identityDocument: e });
                                checkFormValidation(form, "identityDocument", e);
                            }
                        }}
                        filetype={["pdf", "csv"]}
                        filename="Identification document"
                        validationName="identityDocument"
                        checkFormValidation={checkFormValidation}
                    />

                    <div className="mt-10 flex space-x-2 items-center justify-between">
                        <ButtonLoader type="button" onClick={goPrevious} loading={false} className="max-w-[10rem] btn btn-block btn-lg btn-white">
                            <IconArrowLeft className="mr-3" />
                            <span>Back</span>
                        </ButtonLoader>

                        <ButtonLoader
                            type="submit"
                            className="max-w-[16rem] btn btn-block btn-lg btn-primary"
                            disabled={(ownerIsNigerian && !bvnValidationComplete) || verifyLoading}
                            loading={bvnAuthLoading || otpEmailSendLoading || businessVerificationUpdateLoading}>
                            {bvnAuthLoading ? 'Requesting BVN Validation' : 'Continue'}
                        </ButtonLoader>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default OwnerInformation;
