import { ReactNode, SetStateAction, useEffect, useRef, useState } from "react";
import useToolkit from "hooks/useToolkit";
import ContextProps from "./ContextProps";
import {
  closeProductEditModal,
  getProductCategories,
  getProducts,
  resetVendorParams,
} from "store/ecom/action";
import { defaultProductForm } from "./export";
import { RootState } from "store/store";

type Props = {
  children: ReactNode;
  sessionModalOpen: boolean;
  setSessionModalOpen: React.Dispatch<SetStateAction<boolean>>;
}

const ContextProvider = ({ sessionModalOpen, setSessionModalOpen, children }: Props) => {
  const { dispatch, useSelector, isVendorBusiness } = useToolkit();
  const [sessionStep, setSessionStep] = useState(1);
  const [productImgs, setProductImgs] = useState<any>([]);

  const [productForm, setProductForm] = useState(defaultProductForm);
  const { isEditingProduct } = useSelector((state: RootState) => state.ecom)
  const isEditing = useRef(false);

  const [img1, setImg1] = useState(null);
  const [img2, setImg2] = useState(null);
  const [img3, setImg3] = useState(null);
  const [img4, setImg4] = useState(null);
  const [img5, setImg5] = useState(null);
  const [img6, setImg6] = useState(null);

  const incrementSessionStep = () => setSessionStep((step: any) => step + 1)
  const decrementSessionStep = () => setSessionStep((step: any) => step - 1)

  const endSession = () => {
    setSessionModalOpen(false);
    setSessionStep(1);
    setProductForm(defaultProductForm);
    setImg1(null);
    setImg2(null);
    setImg3(null);
    setImg4(null);
    setImg5(null);
    setImg6(null);

    dispatch(closeProductEditModal());
    dispatch(getProducts({ 'page': 1, 'limit': 9 }));

    setTimeout(() => {
      dispatch(resetVendorParams())
    }, 500);
  }

  useEffect(() => {
    // if (isVendorBusiness) {
    dispatch(getProductCategories())
    // }
    // eslint-disable-next-line
  }, [isVendorBusiness, sessionModalOpen])

  return (
    <ContextProps.Provider
      value={{
        sessionStep,
        setSessionStep,
        endSession,
        productImgs,
        setProductImgs,
        sessionModalOpen,

        incrementSessionStep,
        decrementSessionStep,
        productForm,
        setProductForm,
        isEditingProduct,
        isEditing,

        img1,
        setImg1,
        img2,
        setImg2,
        img3,
        setImg3,
        img4,
        setImg4,
        img5,
        setImg5,
        img6,
        setImg6,
      }}>
      {children}
    </ContextProps.Provider>
  )
}

export default ContextProvider;
